import * as React from 'react'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {Text} from 'wix-ui-tpa/Text'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {addCircularTabFocus, focusElement, hookToAttributeSelector} from '@wix/wix-events-commons-statics'
import {classes} from '../error-modal.st.css'
import {ButtonStyle} from '../../button-style'
import {SeatsUnavailableProps} from './interfaces'

export const SeatsUnavailable = ({close}: SeatsUnavailableProps) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()

  React.useEffect(() => {
    focusElement({selector: hookToAttributeSelector(DH.MODAL_TITLE)})
    addCircularTabFocus(hookToAttributeSelector(DH.CLOSE_BUTTON), hookToAttributeSelector(DH.CLOSE_BUTTON))
  }, [])

  return (
    <div className={classes.container}>
      <div className={classNames(classes.content, {[classes.desktopContent]: !isMobile})}>
        <div className={classes.text}>
          <Text
            data-hook={DH.MODAL_TITLE}
            tagName="h2"
            className={classNames(classes.title, {[classes.mobileTitle]: isMobile})}
          >
            {t('seatings_seatsUnavailableModal_title')}
          </Text>
          <Text tagName="p" className={classes.subtitle}>
            {t('seatings_seatsUnavailableModal_subtitle')}
          </Text>
        </div>
        <ButtonStyle
          text={t('seatings_seatsUnavailableModal_button')}
          onClick={() => close()}
          fullWidth={false}
          dataHook={DH.CLOSE_BUTTON}
        />
      </div>
    </div>
  )
}
