import {hasSeatingPlan} from '@wix/wix-events-commons-statics'
import {isEditor} from '../../../../../../commons/selectors/environment'
import {isReservationUnavailable} from '../../../selectors/reservation'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {TicketsPickerOwnProps, TicketsPickerRuntimeProps} from './interfaces'
import {TicketsPicker as Presentation} from './tickets-picker'

export const mapRuntime = ({
  state,
  actions: {openCheckoutUnavailable},
}: DetailsPageAppProps): TicketsPickerRuntimeProps => ({
  tickets: state.tickets,
  reservationUnavailable: isReservationUnavailable(state),
  componentSettings: state.component.settings,
  isEditor: isEditor(state),
  withSeatingPlan: hasSeatingPlan(state.event),
  openCheckoutUnavailable,
})

export const TicketsPicker = connect<TicketsPickerOwnProps, TicketsPickerRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
