import * as React from 'react'
import {SEATING_ERROR} from '../../../../actions/seating'
import {RESERVATION_ERROR} from '../../../../reducers/reservation'
import {SeatsUnavailable} from './seats-unavailable'
import {SomethingWentWrong} from './something-went-wrong'
import {TicketLimitReached} from './ticket-limit-reached'

export const ErrorModal = ({
  seatingError,
  reservationError,
}: {
  seatingError: SEATING_ERROR
  reservationError: RESERVATION_ERROR
}) => {
  if (reservationError === RESERVATION_ERROR.PLACE_RESERVED) {
    return <SeatsUnavailable />
  } else if (reservationError === RESERVATION_ERROR.SOMETHING_WENT_WRONG) {
    return <SomethingWentWrong />
  } else if (seatingError === SEATING_ERROR.TICKET_LIMIT_REACHED) {
    return <TicketLimitReached />
  }
  return null
}
