import * as React from 'react'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {Text} from 'wix-ui-tpa/Text'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {addCircularTabFocus, focusElement, hookToAttributeSelector} from '@wix/wix-events-commons-statics'
import {CloseButton} from '../../../common/close-button'
import {classes} from '../error-modal.st.css'
import {ButtonStyle} from '../../button-style'
import {TicketLimitReachedProps} from './interfaces'

export const TicketLimitReached = ({ticketLimitPerOrder, setSeatingError}: TicketLimitReachedProps) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()

  React.useEffect(() => {
    focusElement({selector: hookToAttributeSelector(DH.MODAL_TITLE)})
    addCircularTabFocus(
      hookToAttributeSelector(DH.CLOSE_BUTTON),
      hookToAttributeSelector(DH.TICKET_LIMIT_REACHED_MODAL_BUTTON),
    )
  }, [])

  return (
    <div className={classes.container}>
      <div className={classNames(classes.content, {[classes.desktopContent]: !isMobile})}>
        <CloseButton
          className={classes.closeIcon}
          closeButtonAsButton={false}
          closeModal={() => setSeatingError(null)}
        />
        <div className={classes.text}>
          <Text
            data-hook={DH.MODAL_TITLE}
            tagName="h2"
            className={classNames(classes.title, {[classes.mobileTitle]: isMobile})}
          >
            {t('seatings_ticketLimitReachedModal_title')}
          </Text>
          <Text tagName="p" className={classes.subtitle}>
            {t('seatings_ticketLimitReachedModal_subtitle', {limit: ticketLimitPerOrder})}
          </Text>
        </div>
        <ButtonStyle
          dataHook={DH.TICKET_LIMIT_REACHED_MODAL_BUTTON}
          text={t('seatings_ticketLimitReachedModal_button')}
          onClick={() => setSeatingError(null)}
          fullWidth={false}
        />
      </div>
    </div>
  )
}
