import React, {useCallback, useEffect} from 'react'
import {Type} from '@wix/ambassador-seating-v1-seating-plan/types'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {TextButton} from 'wix-ui-tpa/TextButton'
import ChevronRightSmall from 'wix-ui-icons-common/on-stage/ChevronRightSmall'
import DeleteSmall from 'wix-ui-icons-common/on-stage/DeleteSmall'
import classNames from 'classnames'
import {Counter} from 'wix-ui-tpa/Counter'
import {BI_ORIGINS} from '@wix/wix-events-commons-statics'
import {useColors} from '../../../../../hooks/colors'
import {ButtonStyle} from '../../button-style'
import {SEATING_ERROR} from '../../../../../actions/seating'
import {getPlaceInfoText} from '../../../../../selectors/seating'
import {classes} from './ticket-action.st.css'
import {TicketActionProps} from './index'

export const TicketAction = ({
  showAccessibilityMode,
  inDescription,
  onClick,
  place,
  selectedTicketQuantity,
  selectedTicketsQuantity,
  eventTicketLimitPerOrder,
  chooseSeatMode,
  onAddClick,
  scheduled,
  addPlaceToBasket,
  setSeatingError,
  description,
  basketView = false,
}: TicketActionProps) => {
  const {darkColor} = useColors()
  const {t} = useTranslation()
  const {elementType, capacity} = place
  const area = elementType === Type.AREA
  const [amount, setAmount] = React.useState(showAccessibilityMode && area ? selectedTicketQuantity : 1)
  const min = showAccessibilityMode ? 0 : 1
  const totalSelectedTicketsQuantity =
    showAccessibilityMode && selectedTicketQuantity
      ? selectedTicketsQuantity - selectedTicketQuantity
      : selectedTicketsQuantity
  const max = chooseSeatMode ? 1 : Math.min(capacity, eventTicketLimitPerOrder - totalSelectedTicketsQuantity)
  const placeInfoText = getPlaceInfoText(place, t)

  useEffect(() => {
    setAmount(showAccessibilityMode || selectedTicketQuantity ? selectedTicketQuantity : 1)
  }, [selectedTicketQuantity])

  const handleCounterChange = useCallback(
    (value: string) => {
      const newValue = Number(value)

      if (newValue > max) {
        if (!chooseSeatMode) {
          setSeatingError(SEATING_ERROR.TICKET_LIMIT_REACHED)
        }
      } else {
        if (showAccessibilityMode || chooseSeatMode) {
          addPlaceToBasket({placeId: place.id, count: newValue, origin: BI_ORIGINS.SEATINGS_MODAL_TICKET_LIST})
        }
        setAmount(newValue)
      }
    },
    [max, chooseSeatMode, setSeatingError, showAccessibilityMode, addPlaceToBasket, place.id],
  )

  if (basketView) {
    return description ? (
      <TextButton
        data-hook={DH.MORE_BUTTON}
        className={classNames(classes.moreInfo, {[classes.buttonNotWired]: !darkColor})}
        suffixIcon={<ChevronRightSmall />}
        onClick={onClick}
        aria-label={`${t('seatings_ticket_moreInfo')} ${placeInfoText}`}
      >
        {t('seatings_ticket_moreInfo')}
      </TextButton>
    ) : null
  }

  if (scheduled || inDescription) {
    return null
  }

  if (showAccessibilityMode && (!area || chooseSeatMode)) {
    const buttonText = place.inBasket ? t('seatings_removeFromBasket') : t('seatings_addToBasket')

    return (
      <TextButton
        data-hook={DH.ADD}
        className={classNames({
          [classes.addButton]: !place.inBasket,
          [classes.removeButton]: place.inBasket,
          [classes.buttonNotWired]: !darkColor,
        })}
        prefixIcon={place.inBasket ? <DeleteSmall /> : undefined}
        aria-label={`${buttonText} ${placeInfoText}`}
      >
        {buttonText}
      </TextButton>
    )
  }

  if (place.selected || area) {
    return (
      <>
        {area ? (
          <Counter
            className={classes.counter}
            incrementAriaLabel={`${t('seatings_area_counter_increment')} ${placeInfoText}`}
            decrementAriaLabel={`${t('seatings_area_counter_decrement')} ${placeInfoText}`}
            inputAriaLabel={t('seatings_area_counter_input')}
            errorTooltipMaxWidth={300}
            value={amount}
            min={min}
            max={max}
            onChange={handleCounterChange}
            aria-pressed={place.selected}
          />
        ) : null}
        {place.selected ? (
          <ButtonStyle
            key="add-to-basket-button"
            className={classes.buttonNoMargin}
            dataHook={DH.ADD}
            text={t('seatings_add')}
            onClick={() => onAddClick(amount)}
          />
        ) : null}
      </>
    )
  }

  return null
}
